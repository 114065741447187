import React, { useContext } from "react";
import OkArrowGray from "../../images/svg/ok-arrow-gray";
import { UserContext } from "../../contexts/userContext";
import { FREE_PLAN_ID } from "../subscriptionPlans/subscriptionPlansModal";
import Styles from "./subscriptionPlanCard.module.css";
import moment from "moment-timezone";

const SubscriptionPlanCard = (props) => {
  const { onPress, plan = {}, selected = false, isNewPlan, subsMainRef } = props;
  const { user } = useContext(UserContext);

  const dateFormat = "MM/DD/YY";

  const expirePlanDate = user.isInTrialPeriod
    ? moment(parseInt(user.trialEndDate)).format(dateFormat)
    : moment(parseInt(user.expirationDateMs)).format(dateFormat)

  let discountShow = plan.productId === FREE_PLAN_ID ? false : true;

  let myPlan = plan;
  let isCurrentPlan = false;
  let isUserPlan = false

  if (user.isSubscribed && user.itemId === plan.productId) {
    isUserPlan = true
    myPlan = user.currentSubsPlan;
    isCurrentPlan = true;
  } else if (!user.isSubscribed && plan.productId === FREE_PLAN_ID) {
    isUserPlan = false
    myPlan = plan;
    isCurrentPlan = true;
  }

  return (
    <div
      ref={ref => (subsMainRef.current[plan.productId] = ref)}
      className={`${Styles.container} ${selected && Styles.selected}`}
      onClick={onPress}
    >
      <div className={Styles.titleContainer}>
        <div className={Styles.planNameDiv}>
          <span className={Styles.title}>{myPlan?.title}</span>
          {myPlan.productId !== "free" &&
            <span className={Styles.periodicityTxt}>
              {myPlan.periodicity.toLowerCase() === "yearly" ? "annual" : myPlan.periodicity.toLowerCase()}
            </span>
          }
        </div>

        {(isCurrentPlan || isNewPlan) && (
          <div className={Styles.currentDivContainer}>
            <div
              className={isNewPlan ? Styles.newPlanView : Styles.discountTextView}
            >
              <span className={Styles.discountTextStyle}>
                {user.isInTrialPeriod ?
                  '7-day Free Trial' :
                  isNewPlan
                    ? "New Plan"
                    : isCurrentPlan
                      ? "Current Plan"
                      : null}
              </span>

            </div>
            {myPlan.productId !== "free" && user.itemId === myPlan.productId && <div className={Styles.divPlanDate}>
              <span className={Styles.planDate}>
                <strong>
                  {((user.isInTrialPeriod && !user.isCancelled) || !user.isCancelled) ? 'Renews: ' : "Expires: "}
                </strong>
                {expirePlanDate}
              </span>
            </div>}
          </div>
        )}
      </div>

      {myPlan.productId !== "free" &&
        (myPlan.periodicity === "MONTHLY" ? (
          <div className={Styles.priceContainer}>
            <span className={Styles.priceTextDark}>
              {isUserPlan ?
                `$${myPlan.monthlyPrice.toFixed(2)}/mo`
                :
                <>
                  <span className={Styles.lineThrough}>
                    ${myPlan.actualMonthlyPrice.toFixed(2)}
                  </span> on sale for ${myPlan.monthlyPrice.toFixed(2)} <span className={Styles.priceTextLight}>(${myPlan.yearlyPrice.toFixed(2)}/yr)</span>
                </>
              }
            </span>
          </div>
        ) : (
          <div className={Styles.priceContainer}>
            <span className={Styles.priceTextDark}>
              {isUserPlan ?
                `$${myPlan.yearlyPrice.toFixed(2)}/yr`
                :
                <>
                  <span className={Styles.lineThrough}>
                    ${myPlan.actualMonthlyPrice.toFixed(2)}
                  </span> on sale for ${myPlan.monthlyPrice.toFixed(2)} <span className={Styles.priceTextLight}>(${myPlan.yearlyPrice.toFixed(2)}/yr)</span>
                </>
              }
            </span>
          </div>
        ))}

      {/* {isNewPlan ?
          <div className={Styles.divPlanDate}>
            <span className={Styles.planDate}>
              <strong>
                {"Starts: "}
              </strong>
              {" 01/01/2024"}
            </span>
            <span className={Styles.planDate}>
              <strong>
                {"Renews: "}
              </strong>
              {" 12/31/2024"}
            </span>
          </div>
          :
          <div className={Styles.divPlanDate}>
            <span className={Styles.planDate}>
              <strong>
                {"Expires: "}
              </strong>
              {" 01/01/2024"}
            </span>
          </div>
        } */}
      <div className={Styles.benefitsContainer}>
        {myPlan.benefits.map((benefit, index) => (
          <div key={index} className={Styles.benefitContainer}>
            <OkArrowGray />
            <span className={index == 2 ? Styles.benefitBold : Styles.benefit}>
              {benefit}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubscriptionPlanCard;
